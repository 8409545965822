@import '_tabdesign.scss';

@import '_modalpopupdesign';

@import 'custom';

@import 'slimscroll';

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

.nav-icon {
  font-size: 1.2rem !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding-top: 12px !important;
}

.p-tag {
  font-weight: 400;
}

.app-sidebar {
  display: block;
  height: calc(100% - 20px);
  overflow: auto;
}

.sidebar-collapsible {
  z-index: 1000;
  margin-top: 15%;
  position: fixed;
  display: block;
}

button.white-bg {
  background-color: white !important;
}

.p-dropdown-filter-container {
  display: grid;
}

.publisher-node {
  border-radius: 40px;
}

.tooltip-max-content > .p-tooltip-text {
  width: max-content !important;
}

.tooltip-big-width > .p-tooltip-text {
  width: 315px !important;
}
.tooltip-big-width2 > .p-tooltip-text {
  width: 395px !important;
}

.app-dialog-footer {
  display: block !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  padding: 8px !important;
}

.table-wrapper,
.custom-panel-border {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  gap: 0.5rem;
}

.p-dropdown-filter-container {
  display: grid;
}

// #commonauth box start
.auth-container {
  min-width: 420px;
}

.auth-card {
  &.p-card {
    min-height: 500px;
    border-radius: 2.4rem !important;
    overflow: hidden;
  }
}

/* tablet breakpoint */
@media (max-width: 768px) {
  .auth-container {
    min-width: 385px;
  }
}
// end #commonauth box start

.horizontal-vertical-center {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// campaign builder code starts
.campaign-audiences-wrapper {
  height: calc(100vh - 240px);
  overflow: auto;
}

.create-campaign-builder {
  .audiencelist {
    display: block;
    overflow: auto;
    height: calc(100vh - 400px);
  }
}

.small-actions {
  button {
    width: 30px !important;
    border-radius: 50% !important;
  }
}
// ends campaign builder code

//#datausage page starts
.data-usage-popup {
  max-height: calc(100vh - 200px);
}
//#datausage end

.margin-minus-3 {
  margin: -3px;
}

.support-icon {
  width: 24px;
  height: 24px;
  background-image: url('/assets/img/icons/support.svg');
}

.requiredField:after {
  content: ' *';
  color: red;
}

/* When the checkbox is checked, add a blue background */
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.modal-sm {
  width: $modal-sm;
}
.modal-md {
  width: $modal-md;
}
.modal-lg {
  width: $modal-lg;
}
.modal-xl {
  width: $modal-xl;
}

.orangethemepopup {
  .p-dialog-content {
    border-top: 3px solid $orange-color !important;
  }

  .fa,
  .pi,
  .p-dailog-header span,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  .close {
    color: $orange-color !important;
  }

  div.p-dialog-content {
    text-align: center;
  }
}

.redthemepopup {
  .p-dialog-content {
    border-top: 3px solid $red-color !important;
  }

  .fa,
  .pi,
  .p-dialog-header h3,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  .close {
    color: $red-color !important;
  }
}

.other-geos-radio {
  .p-button {
    margin-right: 5px;
  }
}

.p-button:focus {
  box-shadow: 0 0 0 0.1rem #df9eea;
}

.operator-select-button {
  .p-button {
    font-size: 10px;
    padding: 2px 3px;
  }
}

.p-button-group .p-button:first-of-type:not(:only-of-type),
.p-button-group p-button:first-of-type:not(:only-of-type) .p-button,
.p-buttonset .p-button:first-of-type:not(:only-of-type),
.p-buttonset p-button:first-of-type:not(:only-of-type) .p-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-button-group .p-button:last-of-type:not(:only-of-type),
.p-button-group p-button:last-of-type:not(:only-of-type) .p-button,
.p-buttonset .p-button:last-of-type:not(:only-of-type),
.p-buttonset p-button:last-of-type:not(:only-of-type) .p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-button-group .p-button:not(:first-of-type):not(:last-of-type),
.p-button-group p-button:not(:first-of-type):not(:last-of-type) .p-button,
.p-buttonset .p-button:not(:first-of-type):not(:last-of-type),
.p-buttonset p-button:not(:first-of-type):not(:last-of-type) .p-button {
  border-radius: 0;
}

.enablePointerOnDisabledButton {
  pointer-events: auto;
  border-color: orange !important;
  opacity: 1 !important;
}

.top-menu-icon {
  font-size: 22px;
  margin: 0 2px;
}

.highlight {
  outline-color: var(--gray-700);
  background-color: var(--surface-hover);
}

popover-container {
  z-index: 10000;
}
