/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

.mbtn-10 {
  margin-bottom: 15px !important;
}

.nv-btnprimary {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
}

.nv-btnprimary:disabled {
  background-color: #c8ced3;
  color: #000;
}

.nv-btnoutlined {
  background-color: transparent;
  border: 1px solid #3071f4;
  color: #3071f4;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  border-radius: var(--button-border);
  cursor: pointer;
}

.nv-btnlined {
  color: #9b9b9b !important;
  text-decoration: underline !important;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
  border: none;
}

.nv-btndefault {
  background-color: #8d8d8d;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
}

.nv-btnclrlined {
  color: #4b1f78;
  background-color: transparent;
  border: none;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
  text-decoration: underline !important;
}

.nv-btndefaultlined {
  color: #9b9b9b;
  background-color: transparent;
  border: none;
  padding: 8px 15px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
  text-decoration: underline !important;
}

.filterbtn {
  background: transparent;
  border: none;
  line-height: 35px;
  padding: 0px 20px;
  color: #330066;
  font-size: 12px;
  font-weight: bold;
}

.filterbtn:focus {
  outline: none;
}

.filterbtn .sorticon {
  padding-left: 5px;
}

.nv-hd1 {
  color: #330066;
  font-size: 28px;
  font-weight: normal;
}

.nv-hd2 {
  color: #4d178f;
  font-size: 16px;
  font-weight: bold;
}

.nv-hd3 {
  color: #330066;
  font-size: 12px;
  font-weight: bold;
}

.nv-hdefault {
  color: #000000;
  font-size: 13px;
  margin-top: 25px;
  line-height: 15px;
}

.nv-clrlnk {
  color: #6a23c8 !important;
  font-weight: bold;
  font-size: 10px;
}

.nv-lnk {
  color: #3071f4 !important;
  font-weight: bold;
  font-size: 10px;
  text-decoration: underline !important;
}

.nv-defaultlnk {
  text-decoration: underline !important;
}

.nv-input1 {
  box-shadow: 0px 0px 5px #d6d6d6;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 10px;
  border-radius: 0px;
  height: 38px;
  border: none;
  max-width: 210px;
}

/* nv search box */
.nv-srhinput {
  position: relative;
}

.nv-srhinput .searchicon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #c7c7c7;
}

.nv-srhinput .nv-input1 {
  padding-left: 36px;
}

/* nav search box */
.nv-select {
  box-shadow: 1px 1px 5px #888;
  padding: 16px 15px;
  border: 1px solid #c8c8c8;
  font-weight: bold;
  font-size: 10px;
}

.nvcard {
  border: none;
}

.card.nvcard {
  background: transparent;
}

.rec1 {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 6px;
  margin-right: 10px;
}

.nv-primarybg {
  background-color: #330066;
}

.nv-secondarybg {
  background-color: #26004b;
}

.nv-thirdbg {
  background-color: #450089;
}

.nv-fourthbg {
  background-color: var(--primary-color);
}

.nv-fifthbg {
  background-color: var(--gray-002);
}

.nv-defaultbg {
  background-color: #d8d8d8;
}

h3.disablehd {
  font-size: 15px;
  background: #efefef;
  padding: 5px;
}

.mrgbtm10 {
  margin-bottom: 10px;
}

.padbtm0 {
  padding-bottom: 0px !important;
}

.padright15 {
  padding-right: 15px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.algncentre {
  text-align: center;
}

.nv-contentheader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 1px solid #e62c5d;
}

.invalid-feedback {
  padding-top: 9px;
  font-size: 12px;
  font-weight: bold;
  color: #e62c5d;
  overflow: hidden;
}

.nv-input1:focus {
  border: 1px solid #33245e;
  outline: none !important;
  box-shadow: 0 0 0 1px #33245e4f;
}

.nv-contentbody p {
  font-size: 14px;
}

.footrft {
  float: right;
}

.footlft {
  float: left;
}

ul.foottab li {
  float: left;
  list-style: none;
  padding: 0px 15px;
}

ul.foottab li a,
ul.foottab li span {
  color: #9b9b9c !important;
  font-weight: bold !important;
  font-size: 10px;
}

.loginfootmenu {
  position: absolute;
  left: 0px;
  bottom: 20px;
  padding: 0px 50px;
}

.authen-logo {
  margin: 40px 0px 0px 0px;
}

.fieldhelp .helpicon {
  color: #33245e;
  font-size: 8px;
  border: 1px solid #33245e;
  display: inline-block;
  padding: 0px 4px;
}

.helpdescs h6 {
  text-align: left;
  font-size: 9px;
  font-weight: 500;
  color: #afb0b1;
  line-height: 15px;
  margin-bottom: 10px;
}

.helpdescs ul {
  padding: 0px;
}

.helpdescs ul li {
  padding-left: 12px;
  list-style: none;
  text-align: left;
  font-size: 9px;
  position: relative;
  color: #424244;
  line-height: 12px;
  margin-bottom: 6px;
}

.helpdescs ul li span {
  position: absolute;
  left: 0px;
  font-size: 12px;
  line-height: 12px;
}

.helpdescs {
  display: none;
}

.fieldhelp .helpicon:hover + .helpdescs {
  display: block;
}

.card-body {
  padding: 0px 45px;
}

.card-group {
  padding-top: 0px;
}

.nv-overly {
  height: 100vh;
}

.pad20 {
  padding: 20px;
}

.loginoverlay {
  max-width: 600px;
  width: 100%;
  z-index: 99;
}

.loginwrap {
  margin: 0px auto;
  max-width: 420px;

  .input-group {
    max-width: 300px;
    margin: 0px auto;
  }

  .nv-input1 {
    max-width: 100%;
  }
}

.nova-bg2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -590px;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.3;
  right: calc(-55% + 100px);
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background: var(--purple-006);
}

.sidebar-fixed .app-header + .app-body .sidebar {
  height: calc(100vh);
  top: 0px;
  z-index: 9999;
}

app-sidebar-minimizer.sidebar-minimizer {
  width: auto;
  flex: auto !important;
  position: absolute !important;
  top: 5px;
  height: 40px;
  right: -26px;
  background: transparent !important;
}

body.app.header-fixed.sidebar-lg-show.sidebar-fixed.sidebar-minimized.brand-minimized .app-header.navbar {
  padding-left: 80px;
}

body.header-fixed.sidebar-lg-show.sidebar-fixed.sidebar-minimized.brand-minimized .sidebar-minimizer {
  right: -40px;
}

.sidebar-minimized {
  width: 100%;
}

.sidebar .sidebar-minimizer::before {
  width: 100%;
  height: 50px;
  background-size: 12.5px;
  transition: 0.3s;
  transform: rotate(0deg);
  content: '';
  font-family: var(--font-family);
  text-align: center;
  padding: 13px;
  font-weight: normal;
  background-image: none;
  color: var(--purple-006);
}

.sidebar-minimized .sidebar .sidebar-minimizer::before {
  width: 100%;
  transform: rotate(0deg) !important;
}

.nav-link.nav-dropdown-toggle {
  background: var(--purple-006);
}

.sidebar .nav-link.active {
  color: #ffffff;
  background: var(--purple-006);
}

.sidebar .nav-link .nav-icon {
  margin: 0 15px 0 0;
  color: #ffffff;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff !important;
}

.sidebar .nav-link:hover {
  background-color: #440089;
  color: #fff !important;
}

.sidebar .nav-dropdown-toggle::before {
  transition: transform 0.9s;
  transform: rotate(270deg);
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(90deg);
}

.header-fixed .app-header {
  position: fixed;
  z-index: 99;
  width: 100%;
  padding-left: 235px;
}

.font-size-smaller {
  font-size: smaller;
}

/*--------------------------------------------------------------
# Login Form
--------------------------------------------------------------*/
.nvform-label {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.nvLogout-label {
  cursor: pointer;
  color: #9b9b9c !important;
  font-weight: bold !important;
  font-size: 11px;
}

.inline-label {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.error-message {
  color: #000;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 20px 0px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  border: 1px solid #fd6d6d;
  padding: 10px 25px;
  background: #fbf4f4;
  border-radius: 4px;
  position: relative;
  min-height: 36px;
}
.error-message::before {
  line-height: 17px;
  content: 'x';
  color: #fff;
  background-color: #fd6d6d;
  width: 20px;
  display: inline-block;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  margin-right: 5px;
}
.themealertclosebtn {
  position: absolute;
  right: 8px;
  top: 8px;
  background: transparent;
  color: #525252;
  font-weight: 400;
}
.themealertclosebtn:hover,
.themealertclosebtn:focus {
  outline: none;
}

.input-group .error-message {
  display: none;
}

.nv-form-group {
  display: inline-block;
  width: 100%;
  margin: 0px auto;
}

/* Error Styling */
.error input {
  border: 1px solid #cc0033;
  outline: none;
}

.error .error-message {
  display: inline-block;
}

/* radio button design */
/* The container */
.form-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 28px;
  width: auto;
  float: left;
}

.pad25 {
  padding: 0px 25px;
}

.privacy-footer {
  position: absolute;
  bottom: 0;

  ul li {
    list-style: none;
    color: #fff;
    line-height: 20px;
  }

  ul li a {
    color: #fff;
    font-size: 11px;
    font-weight: normal;
  }

  ul {
    padding-left: 1rem;
  }
}

/* Hide the browser's default radio button */
.form-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: inline-block;
  left: 4px;
  right: auto;
  z-index: 9;
  width: 15px;
  height: 15px;
  top: 7px;
}

/* Create a custom radio button */
.form-radio .radiomark {
  position: absolute;
  top: 7px;
  left: 4px;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.form-radio:hover input ~ .radiomark {
  background-color: #ccc;
}

/* Style the indicator (dot/circle) */
.radiomark:after {
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: var(--purple-006);
}

/* When the radio button is checked, add a blue background */
.form-radio input:checked ~ .radiomark {
  background-color: #f7f7f7;
  border: 1px solid var(--purple-006);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.form-radio .radiomark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.form-radio input:checked ~ .radiomark:after {
  display: block;
}

/* data table */

.nv-datatable thead tr th::before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: #330066;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.nv-datatable,
.nv-datatable td,
.nv-datatable th {
  border: none !important;
  font-size: 12px;
}

.nv-datatable thead tr th {
  background: var(--gray-002);
  color: #330066;
  z-index: 100;
  top: 0;
}

.nv-datatable thead tr th .sorticon {
  padding-left: 8px;
  font-size: 10px;
  position: relative;
  top: -1px;
}

.nv-icon {
  cursor: pointer;
}

.nv-datatable .nv-icon {
  padding: 0px 8px;
  color: #6b20c9;
}

.nv-datatable .nv-icon.disabled {
  color: #808080;
}

.nv-icon-gray {
  padding: 0px 8px;
  color: #9b9b9c;
  cursor: pointer;
}

.tablerow {
  width: 100%;
  display: contents;
}
.tablerow:nth-of-type(2n) tr {
  background-color: #fbfbfb !important;
}

.nv-datatable > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}

.nv-datatable > tbody > tr:nth-of-type(even) {
  background-color: #fbfbfb;
}

.modal-content {
  border-radius: 0px;
  border-color: #9b9b9c !important;
  border-top: 3px solid darkblue !important;

  .card-header {
    padding: 0.5rem 1rem;
  }

  .card-header,
  .card-footer {
    background-color: white;
    border: none;
  }

  .card-body {
    color: #9b9b9c !important;
    font-weight: bold !important;
    font-size: 11px;

    label {
      color: var(--purple-006) !important;
    }
  }

  .nv-hd2 {
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0.5rem !important;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  font-size: 12px !important;
}

.nv-brand-wrap {
  width: 100%;
  margin: 20px auto;
}

.nvlt-grid {
  display: inline-flex;
  margin: 40px 0px 70px;
  padding-right: 54px;
}

.nvlt-grid .nvlt-item {
  padding: 15px 45px;
}

.nvlt-grid .nvlt-icon {
  color: #cd3cfc;
  font-size: 26px;
  margin-bottom: 6px;
  display: inline-block;
}

/* bluepan design */

.nvlt-grid .nvlt-item h4 {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}

.nvlt-grid p {
  font-size: 12px;
  font-weight: bold;
  color: #5f5f5f;
  margin-bottom: 10px;
}

span.verticsplit {
  display: inline-block;
  width: 1px;
  height: auto;
  background: #e0e0e0;
}

.contenthd {
  font-size: 12px;
  background: var(--gray-002);
  padding: 10px 15px;
  color: #330066;
}

.nopadlft {
  padding-left: 0px !important;
}

.nopadrt {
  padding-right: 0px !important;
}

.nv2-list {
  margin-top: 0px !important;
}

.nv2-listwrap {
  padding-left: 60px;
  position: relative;
  display: flex;

  p {
    font-size: 8px;
    line-height: 10px;
    color: #a5a5a5;
    margin-bottom: 8px;
  }

  label {
    float: left;
    position: absolute;
    width: 60px;
    left: 0px;
    font-weight: bold;
    top: 0px;
    line-height: 12px;
    font-size: 9px;

    .statusicon {
      width: 8px;
      height: 8px;
      display: inline-block;
      background: var(--purple-006);
      border-radius: 10px;
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}

.nv2list-scroll {
  height: 22vh;
  overflow-y: auto;
}

.nvdragdrp {
  height: 24vh;
  border: 2px dashed #d6d6d6;
}

.nv-panel-default > .nv-panel-heading {
  border-color: #ddd;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: #330066 !important;
  //font-family: NotoSansregular !important;
  font-weight: bolder !important;
  background-color: var(--gray-002);
}

.nv-panel-default {
  border-color: #ddd;
}

.nv-panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.nv-panel-body {
  padding: 15px;
}

.item span {
  font-weight: bold;
  font-size: 10px;
}

.itemunits {
  color: var(--primary-color);
}

.nvrowscroll {
  padding: 4px 15px 10px;
  height: 100%;
  overflow-y: auto;
}

.nv-sys {
  .card {
    border: none;
  }

  .card-header {
    color: #330066 !important;
    // font-family: NotoSansregular !important;
    font-weight: bolder !important;
    background-color: var(--gray-002);
  }

  .card-body {
    h6 {
      font-size: 0.8rem !important;
      color: #330066 !important;
      //font-family: NotoSansregular !important;
    }
  }
}

.nv-fourthcolor {
  color: var(--primary-color);
}

.nv-thirdcolor {
  color: #330066;
}

.nv-secondcolor {
  color: var(--primary-color) !important;
}

.nv-item {
  font-weight: bold !important;
  font-size: 10px !important;
}

.nv-label {
  font-size: 12px;
  font-weight: bolder !important;
  color: #330066;
}

.width56 {
  width: 50% !important;
}

.app-header .navbar-brand {
  display: none;
}

.nv-upload {
  max-width: 300px;
  margin: 15px 15px;
  display: flex;

  .input-group-text {
    font-size: 12px;
    background: #6a23c9;
    color: #fff;
    border-radius: 0px !important;
  }

  .input-group-append {
    margin-left: 5px;
  }

  .custom-file-label {
    text-align: left;
    outline: none !important;
  }

  .custom-file input {
    border-width: 0px;
    outline: none;
  }
}

.combobx {
  background-color: #f0f3f5;
  padding: 0px 15px 15px;
  font-size: 11px !important;

  .comboiconic {
    position: absolute;
    right: 0px;
    top: 15px;
    color: #9c9c9c;
  }

  .card-header {
    border-bottom-width: 0px;
    padding-top: 0px;
  }

  .combobxwrap {
    position: relative;
  }

  app-input {
    width: 100%;
    border-bottom: 1px solid #c8ced3;
    padding-right: 15px;

    input:focus {
      border-color: transparent;
      background-color: transparent;
    }

    input {
      background-color: #f0f3f500;
      box-shadow: none !important;
      height: 40px;
      font-size: 14px;
    }
  }

  .nv-box {
    padding-left: 5px !important;
  }
}

.nv-textarea {
  min-width: -webkit-fill-available;
  background-color: #ededed;
  border: none;
  box-shadow: none;
  min-height: 100px;
}

.nv-grid-textarea {
  min-height: unset;
  min-width: 91% !important;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 37px;
}

.uploadDocbtn {
  align-items: center;
  margin: 25px 0px 15px 5px;

  .nv-btnoutlined {
    background: #6a23c9;
    color: #fff;
    border: none;
  }

  .nv-hd2 {
    margin-bottom: 0px;
  }
}

.nv-datatable.nvrole-table textarea {
  height: 56px !important;
}

.nv-file-input {
  line-height: 26px;
  padding-left: 0px;
  border-color: #e4e4e4;
  width: 220px;
  margin-right: 5px;
}

.nv-file-download {
  color: #ab9e9e;
  font-size: 14px;
  float: left;
  margin-top: 2px;
}

.nv-file-download-icon {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 30px;
}

.pad5 {
  padding-left: 5px !important;
}

.mar15 {
  margin-right: 15px;
}

.mar25 {
  margin-top: 25px !important;
}

.mnwdth {
  min-width: 157px;
}

.mnwdth65 {
  min-width: 90px;
}

.pad4 {
  padding-top: 4.5rem !important;
}

.font10 {
  font-size: 10px !important;
  font-weight: normal !important;
}

.nv-datatable .datatable-header {
  background: var(--gray-002);
  color: #330066;
}

.padtop {
  padding-top: 4.5rem;
}

.font11 {
  font-size: 11px !important;
}

.width30 {
  max-width: 30px !important;
}

.width99 {
  width: 99% !important;
}

.nv-table-background {
  background-color: #fbfbfb;
}

.font18 {
  font-size: 18px !important;
}

.li-list {
  list-style: none !important;
}

.fa-normal {
  font-weight: normal;
}

.nv-noWrap {
  white-space: nowrap !important;
}

.nv-no-min-width {
  min-width: 0px !important;
}

.width200 {
  width: 269px !important;
}

.row-highlight {
  background-color: var(--gray-002) !important;
}

.nv-datatable tbody tr.row-highlight:nth-of-type(odd) {
  background-color: var(--gray-002) !important;
}

.nv-datatable tbody tr.row-highlight:nth-of-type(even) {
  background-color: var(--gray-002) !important;
}

.height200px {
  height: 250px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 9999999;
}

.row-grayout {
  background-color: #aeadaf !important;
}

.text-align-right {
  text-align: right;
}

/*--------------------------------------------------------------
# Google Map Overwrite CSS
--------------------------------------------------------------*/

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint div {
  background: none !important;
}

.gm-control-active {
  width: 20px !important;
  height: 20px !important;
}

.gmnoprint > div {
  background-color: #fff !important;
  width: 20px !important;
  height: 41px !important;
}

.gmnoprint div img {
  width: 9px !important;
  height: 9px !important;
}

.markerIconLabel {
  font-size: 12px;
  color: rgb(0, 0, 0);
}

.maxhtm-32.nv-contentbody {
  height: auto;
  overflow: auto !important;
  max-height: calc(100% - 32px) !important;
}
.maxwth-full {
  max-width: 100%;
}
.minputwidth135 {
  min-width: 135px;
}
.mrgtp-3 {
  margin-top: 3px;
}

.dflex {
  display: flex;
}
.dinlineflx {
  display: inline-flex;
}
.mapsection:focus {
  outline: none;
}
.mapsection div:focus {
  outline: none !important;
}
.btn-group-toggle .nv-icon {
  min-width: 26px;
}

.respfltrbtn label,
.wordbrk {
  word-break: break-all;
}
.max-50.activescroll {
  display: inline-block;
  overflow: auto;
  max-height: calc(100% - 200px);
  width: 100%;
}

.table.table-striped.nv-datatable.striperow {
  max-width: calc(100% - 3px);
}

.reportview {
  height: calc(100vh - 125px);
}

.checkboxalign.form-check {
  display: flex;
  position: relative;
  padding-left: 18px;
  margin-bottom: 2px !important;

  .form-check-input {
    left: 20px !important;
    top: 1px !important;
  }
  .checkmark {
    left: 0px !important;
  }
}

.datimergrp .datimerinput {
  width: 100%;
  max-width: calc(100% - 45px);
}
.maxht90 {
  max-height: calc(100% - 90px) !important;
}
.nv-gridclm .bottombtnset {
  display: flex;
  justify-content: space-between;

  .nv-btnoutlined,
  .nv-btnprimary {
    min-width: auto !important;
    // border: 1px !important;
    width: 100% !important;
  }
  .pull-right {
    padding: 0px 3px;
  }
}

.statusindicator {
  display: flex;
  align-items: center;
}
.statusindicator em {
  padding-left: 0px !important;
}
.actionbtngrp {
  width: max-content;
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
  padding-left: 15px;
}
.sidebar-minimized .sidebar .nav-link .nav-icon {
  margin-right: 0px;
}

.dataactiongrp {
  display: flex;
}

.container {
  width: 100%;
  // display: flex;
  align-items: center;
}
.drag-icon {
  position: relative;
}
.drag-icon i {
  cursor: move;
}
i.disable {
  position: absolute;
  cursor: not-allowed !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: transparent;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* .example-box:last-child {
  border: none;
} */

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.w-10 {
  width: 10%;
}
.w-100 {
  width: 100%;
}
.drag-icon {
  background-color: #3f51b5;
  color: #fff;
  text-align: center;
  padding: 8px 15px;
}
.drag-icon i {
  padding-top: 3px;
}
.drag-content {
  padding-left: 10px;
}

.drag-index {
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
}

.drag-handle {
  display: grid;
  align-items: center;
  cursor: move;

  .handle {
    margin-right: 5px;
  }
}

.custometooltip {
  position: relative;
}

.custometooltip .tooltips {
  display: none;
  position: absolute;
  background: #fff;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid grey;
  top: 100%;
  z-index: 99;
  right: auto;
  left: -250px;
  width: 370px;
}

.custometooltip:hover .tooltips {
  display: inline-block;
}

.custometooltip {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 3px;
}
.custometooltip .fa-info.circle {
  font-size: 13px;
}

.redpopupoverlay .modal-content {
  max-height: 90vh;
}
.redpopupoverlay .modal-body {
  overflow: hidden;
  display: flex;
}
.redpopupoverlay div#dvDeleteAttribute {
  height: 100%;
}
.redpopupoverlay .text-center {
  max-height: calc(100% - 45px);
  overflow: auto;
}
.redpopupoverlay .modal-body > div {
  width: 100%;
}

.namgroup {
  position: relative;
  padding-right: 15px;
  .namegrouplayer {
    white-space: normal !important;
    word-break: break-all;
  }
  app-novatiq-popover {
    position: absolute;
    right: 0px;
  }
}

.campaignhelpertext {
  position: relative;
  display: inline-flex;
  .fa.fa-info-circle {
    position: absolute;
    top: 4px;
    right: -14px;
  }
}

/* ratecard pricing css */

.padtp15 {
  padding-top: 15px !important;
}

.item span {
  font-weight: bold;
  font-size: 10px;
}

.gm-ui-hover-effect,
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none !important;
}

.fieldhelp {
  position: absolute;
  top: 5px;
  left: 96%;
}

.fieldhelp .helpdescs {
  position: absolute;
  width: 223px;
  border: 1px solid #f4d79b;
  padding: 12px 15px;
  left: 25px;
  top: -35px;
  box-shadow: 0px 0px 5px #33245e47;
}

span.helpdescs::before {
  top: 40px;
  border-left: none;
  left: auto;
  height: 0 !important;
  position: absolute;
  left: -13px;
  font-weight: normal;
  font-size: 21px;
  content: '<';
  z-index: 9;
  color: #f3dcad;
  border-bottom: 0px solid #0a0a0a;
  border-top: 0px solid #000;
  border-right: 3px solid #fff;
  line-height: 0px;
  min-width: auto !important;
  min-height: auto !important;
  padding: 0px !important;
  transform: scale(1, 1.4);
  background: #fff;
}

.helpdescs::after {
  content: '';
  position: absolute;
  display: block;
  top: 40px;
  right: auto;
  transform: rotate(-304deg);
  border-style: solid;
  border-width: 6px 0px 6px 9px;
  border-color: #ff000000 #fdfdfd #ffa50000 #ffffff;
  left: -4px;
}

.input-group {
  position: relative;
  display: inline-block;
  width: 100%;
}

/*--------------------------------------------------------------
# Login Form
--------------------------------------------------------------*/

/* Show the checkmark when checked */
.form-check input:checked ~ .checkmark:after {
  display: block;
}

/* data table */

.valign-mdl th {
  vertical-align: middle !important;
}

.nv-datatable thead tr th,
.novatiqGridHeader .grid-col {
  background: var(--gray-002);
  color: #330066;
  z-index: 100;
}

.respfltrbtn label,
.wordbrk {
  word-break: break-all;
}

.itemunits {
  color: var(--primary-color);
}

/* bluepan design */

.status3 .statusicon {
  background-color: #3072f4 !important;
}

.status2 .statusicon {
  background-color: var(--purple-006) !important;
}

.status1 .statusicon {
  background-color: #cc3bfb !important;
}

.status3 .statusicon {
  background-color: #3072f4 !important;
}

.status2 .statusicon {
  background-color: var(--purple-006) !important;
}

.status1 .statusicon {
  background-color: #cc3bfb !important;
}

.nv-upload {
  max-width: 300px;
  margin: 15px 15px;
  display: flex;

  .input-group-text {
    font-size: 12px;
    background: #6a23c9;
    color: #fff;
    border-radius: 0px !important;
  }

  .input-group-append {
    margin-left: 5px;
  }

  .custom-file-label {
    text-align: left;
    outline: none !important;
  }

  .custom-file input {
    border-width: 0px;
    outline: none;
  }
}

.font12 {
  font-size: 12px !important;
}

/*--------------------------------------------------------------
# Google Map Overwrite CSS
--------------------------------------------------------------*/

.valign-mdl th {
  vertical-align: middle !important;
}
.audiencebuilder-row:nth-of-type(2n) th {
  background-color: #fbfbfb !important;
}

.audiencebuilder-row:nth-of-type(2n) tr {
  background: #fbfbfb !important;
}

.tablerow {
  width: 100%;
  display: contents;
}
.tablerow:nth-of-type(2n) tr {
  background-color: #fbfbfb !important;
}

.attribute-management-table {
  height: calc(100vh - 220px);
}

.custometooltip {
  position: relative;
}

.custometooltip:hover .tooltips {
  display: inline-block;
}
.custometooltip .tooltips {
  display: none;
  position: absolute;
  background: #fff;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid grey;
  top: 100%;
  z-index: 99;
  right: auto;
  left: -250px;
  width: 370px;
}

.w-100.w-200 tr th {
  padding-bottom: 5px;
  padding-top: 5px;
}
.w-100.w-200 tr .font10 {
  margin-bottom: 0px;
}
.w-100.w-200 tr:first-child th h6,
.w-100.w-200 tr span {
  margin-bottom: 0px;
}
#tblBrandRowHeader td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.w-100.w-200 {
  max-width: 94%;
  margin: 0px 0px 0px 5%;
}
#trAudiencesRow td {
  padding-top: 5px;
  padding-bottom: 5px;
  h6 {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0px;
  }
}

.w-15 {
  width: 15%;
}
.w-30 {
  width: 15%;
}
.w-26 {
  width: 26%;
}
.w-55 {
  width: 55%;
}

.ratecardtable {
  background: var(--gray-002);
  width: 100%;
  color: #330066;
  z-index: 100;
  font-weight: bolder !important;
  border-spacing: 0px;
  th {
    padding: 0px;
    padding-bottom: 0px;
    text-align: center;
    border-left: 2px solid var(--purple-006) !important;
  }
  td {
    padding: 10px;
    text-align: center;
    border-left: 2px solid var(--purple-006) !important;
  }
}
.w-50 {
  width: 50%;
}
.secondaryheader th,
.secondaryheader td {
  padding: 0px !important;
}
.primaryheader th {
  border: none !important;
  border-left-width: medium;
  background-color: #fff;
  padding-bottom: 0px;
}
.secondaryheader th {
  border-top: 4px solid var(--purple-006);
  border-bottom: 2px solid var(--purple-006);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ratecardtable {
  border-collapse: inherit;
  border-spacing: 0px !important;
  th:first-child {
    text-align: left;
    border-left-width: 2px !important;
    white-space: normal;
    word-break: break-all;
    width: 20%;
  }
  td:first-child {
    text-align: left;
    border-left-width: 2px !important;
    white-space: normal;
    word-break: break-all;
    width: 20%;
  }
  td {
    color: #23282c;
    font-weight: normal;
    background-color: #fff;
    font-size: 12px;
  }
  td:last-child {
    border-right: 2px solid var(--purple-006) !important;
  }
  tr:last-child td {
    border-bottom: 2px solid var(--purple-006);
  }
}
.primaryheader th {
  position: sticky;
}
.secondaryheader th {
  position: sticky;
  top: 17px !important;
  background: var(--gray-002);
  border-bottom: 0px solid var(--purple-006);
  border-top: none;
}

/* popup table 2 start */
.newpopupdatatable th,
.newpopupdatatable td {
  border: 2px solid var(--purple-006);
  border-top-width: 2px;
  border-left-width: 2px;
  border-collapse: collapse;
}
.newpopupdatatable th {
  padding: 2px 15px;
  text-align: center;
  font-weight: 500;
}
.newpopupdatatable td {
  padding: 5px 15px;
  text-align: center;
  font-weight: 500;
}
.newpopupdatatable thead tr:first-child th:first-child {
  border-top-width: 0px;
  border-left-width: 0px;
}
.newpopupdatatable {
  border-collapse: collapse;
  min-width: 400px;
}
.newpopupdatatable td {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-collapse: collapse;
}
.newpopupdatatable tbody tr:last-child td {
  border-bottom-width: 1px;
}
.newpopupdatatable td span {
  padding: 3px 10px;
  display: inline-block;
  background-color: #ddd;
}
.newpopupdatatable th,
.newpopupdatatable td {
  border: 2px solid #777677;
  border-top-width: 2px;
  border-bottom-color: rgb(231, 231, 231);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-bottom-color: #777677;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-collapse: collapse;
}
.newpopupdatatable {
  width: 100%;
  min-width: auto;
}
.verticalbtngroup {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.editpricingpopuppanel .modal-header,
.editpopupcampaign .inviteUserpopup.scrollablepopup .modal-header {
  background-color: var(--gray-002);
  border-radius: 0px;
  border: 3px solid #306 !important;
  padding-bottom: 15px !important;
}
.editpopupcampaign .inviteUserpopup.scrollablepopup .modal-dialog .modal-content {
  border-top-width: 0px !important;
}
.editpopupcampaign .inviteUserpopup.scrollablepopup .modal-body {
  border: 3px solid #306;
  border-top-width: 3px;
  border-top-width: 0px;
}
/* popup table 2 end */

.pl-5 {
  padding-left: 5px !important;
}
.filtertext {
  display: flex;
}
.ht-auto {
  height: auto !important;
}
.pl-15 {
  padding-left: 15px;
}
.pricingtdcell {
  border-left: 1px solid var(--purple-006);
  border-left-width: 1px;
  padding: 8px 8px;
  min-height: 39px;
  border-right: 1px solid var(--purple-006);
  border-right-width: 1px;
}

.secondaryheader th {
  padding: 0px !important;
  border: none;
}

.ratecardtable .secondaryheader th .pricingtdcell {
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom: 2px solid var(--purple-006);
  border-top: 2px solid var(--purple-006);
}

.ratecardtable .secondaryheader th:first-child .pricingtdcell {
  border-left-width: 0px !important;
}
.ratecardtable .secondaryheader th:last-child .pricingtdcell {
  border-right-width: 2px !important;
  min-height: 39px;
}
.minw-95 {
  min-width: 80px;
}
.subtablecell {
  padding: 5px 0.75rem;
}
.maxht50 {
  max-height: calc(100% - 50px);
}

.ht-paralex {
  height: 50%;
  max-height: 50%;
}

@media screen and (max-width: 1360px) {
  .sidebar-fixed .sidebar {
    width: 180px;
  }
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 180px;
  }
  .sidebar .sidebar-nav {
    width: 180px;
  }
  .sidebar .nav {
    width: 180px;
  }
  .restablefont {
    .nv-datatable,
    .nv-datatable td,
    .nv-datatable th {
      font-size: 10px;
    }
  }
  .main .default-container {
    padding: 0 30px;
    height: calc(100vh - 110px) !important;
  }
  .header-fixed .app-header {
    padding-left: 215px;
  }
  .nv-hd4 {
    font-size: 12px;
  }
  .nv-hd2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .ht-paralex {
    max-height: 100%;
  }
}
@media screen and (max-width: 990px) {
  html .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 0px !important;
  }
}

.filtererror {
  padding-left: 10px;
  .close {
    min-width: 20px !important;
  }
}

.plr-5 {
  padding: 0px 5px;
}
.nv-contentbody.max-10 {
  max-height: calc(100% - 10px);
}
.swapcellwidth {
  .nv-responsivetable {
    td {
      position: relative;
    }
  }
}
.drag-handle {
  word-break: break-all;
}
.fx-column {
  flex-direction: column;
}
.tabgroups .nv-btnoutlined {
  border-color: var(--primary-purple) !important;
  color: #000 !important;
  font-size: 12px;
}
.tabgroups .nv-btnoutlined.active {
  background-color: var(--primary-purple) !important;
  color: #fff !important;
}
.tabgroups .nv-btnoutlined:hover {
  background-color: var(--primary-purple) !important;
  color: #fff !important;
}
.pr-15 {
  padding-right: 15px;
}
.mxht-130 {
  max-height: calc(100% - 130px) !important;
}

.maxhtm-160.nv-contentbody {
  height: auto;
  overflow: auto !important;
  max-height: calc(100% - 160px) !important;
}

.closebutton {
  display: flex;
  float: left;
}

.disp-block {
  display: inline-block;
}
.taboverlay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.mapLocationpopup {
  .modal-sm {
    width: 90%;
    max-width: 700px;
    .col-md-12.xlht-auto.d-flex {
      padding: 0px;
    }
  }
  .modal-content {
    background-color: #fafafa;
  }
  .bg-light {
    background-color: #fff !important;
  }
}
.ptb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.supersubtable thead th {
  font-weight: 400;
}

.justifybetween {
  justify-content: space-between;
}
.justifyend {
  justify-content: flex-end;
}

.show-onhover {
  visibility: hidden;
}

a:hover i.show-onhover {
  visibility: visible;
}

#system-dashboard iframe {
  height: calc(100vh - 120px);
}

.novatiqGridHeader {
  border-bottom: 2px solid;
  background: var(--gray-002);
}
.novatiqGridBody {
  height: calc(100% - 55px);
  overflow: auto;
  .col {
    min-height: 60px;
  }
}

.novatiqGridHeader,
.novatiqGridBody {
  .grid-row:nth-of-type(2n) {
    background-color: #fbfbfb;
  }
  .grid-col {
    padding: 12px 5px;
    font-weight: bold;
  }
  .chevron {
    flex: 0 0 35px;
  }
}

.collapsableRow {
  .grod-col {
    min-height: 36px;
  }
}

.display-grid {
  display: grid;
}

.attr-management-grid {
  grid-template-columns: 10px repeat(5, 1fr) 150px;
  grid-gap: 1rem;
}

.grid-wrapper {
  grid-template-rows: max-content;
  display: grid;
  grid-template-columns: 10px repeat(6, minmax(50px, 1fr));
  grid-gap: 1rem;
}

.grid-row {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 10px repeat(6, minmax(50px, 1fr));
  grid-gap: 1rem;
}

.grid-colspan-4 {
  grid-column: span 4; /* span all 5 columns */
}

.grid-preset-values {
  display: grid;
  grid-column: span 7;
  margin: 0 5px 0 27px;
}

.display-center {
  margin: 0 auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.ddl-buyside {
  width: 85%;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.ddlCurrency {
  width: 65px !important;
}

.edit-price-container {
  min-height: 200px;
}

.audiencetable {
  height: calc(100vh - 150px) !important;
}

.campaignbuilder-table {
  height: calc(100vh - 190px) !important;
}

.userrole-container {
  height: calc(100vh - 147px) !important;
}

#datausage-dashboard iframe {
  height: calc(100vh - 170px);
  padding: 0 10px !important;
}

.rate-card-table {
  margin-top: -20px;
  height: calc(100vh - 175px) !important;
}

#commercial-dashboard iframe {
  height: calc(100vh - 120px);
}

.audience-attributes {
  overflow: auto;
  height: calc(100vh - 450px);
  table {
    table-layout: fixed;
    td {
      padding: 10px 5px;
    }
    .audience-operator {
      width: 95px;
    }
    .attribute-name {
      width: 95px;
    }
    .attribute-negate {
      width: 50px;
    }
    .attribute-order {
      width: 25px;
    }
    .attribute-remove {
      padding: 17px 0 0 0;
      width: 25px;
    }
    .nv-icon:hover {
      color: var(--primary-color);
    }
  }
}

.multivalue-col {
  word-break: break-word;
}

.indent-audiences {
  text-indent: 100px;
}

.qr-code {
  height: 150px;
}

.warning-message {
  font-weight: 500;
  border: 1px solid $orange-color;
  padding: 10px 25px;
  background: #fbf4f4;
  border-radius: 4px;
  i {
    color: orange;
    margin: 0 5px 0 -20px;
  }
}

.TFAcode {
  width: 150px;
  font-size: 25px;
  border-radius: 5px;
  letter-spacing: 7px;
  text-indent: 10px;
}

.user-management-second-bar {
  .denote-admin {
    width: 130px;
  }
  .error-message {
    margin-top: -21px;
  }
}

.client-management-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.tr-campaign-name {
  border: 1px solid #efefef !important;
  border-width: 0px 1px 1px 1px !important;
}
.tr-campaign-details {
  border: 1px solid #efefef !important;
  border-width: 1px 1px 0 1px !important;
}

.attribute-management-wrapper {
  height: 100%;
  overflow: auto;
  .nv-icon {
    padding: 10px;
  }
  .exapanded-row {
    .search-header {
      display: flow-root;
    }
  }
}

.whitespace-normal {
  white-space: normal;
}

.font-18 {
  font-size: 18px !important;
}

.attribute-onboarding-table {
  height: calc(100vh - 250px);
  overflow: auto;
}

.attr-end-date {
  font-size: 17px;
}

.campaign-unavailable-tooltip,
.attr-end-date-tooltip {
  max-width: 300px;
}

